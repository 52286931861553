
.container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
  }
  
  .container input {
    position: absolute;
    left: 0;
    
    opacity: 0;
     visibility: hidden;
    z-index: -1; 
    display: none;
   
  }
  

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    /* background-color: #1a3134; */
    border-radius: 50%;
    border:1px solid #1a3134;
  }
    


  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  .container input:checked ~ .checkmark:after {
    display: block;
    
  }
  .container input:checked ~ .checkmark {
    background-color: #1a3134;
   
    
  }


  
  .container .checkmark:after {

    color: white;
    content: "✓";
    width: 7px;
    height: 5px;
    font-size: 10px;
    left: 3px;
    top: 1px;
    font-weight: 800;
  }

  label{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: -0.07px;
    text-align: justify;
    color: #666;
    cursor: pointer;
    margin-top: 20px;
    z-index: 100;
    padding-left: 15px;
  }

  .isBad{
    background-color: var(--red)!important;
    border-color: var(--red)!important;
  }

  .isGood{
    background-color: var(--green)!important;
    border-color: var(--green)!important;
  }

  .isNoSelect{
    background-color: white!important;
    border : solid 2px;
    border-color: var(--green)!important;
  }

  .isDisplayed{
    display: inline;
  }

  .isNotDisplayed{
    display: none!important;
  }

  .app-quiz-label .app-question-bloc{
    margin-left: 20px;

  }
  .qcu label{
    margin-left: 15px;
  }