:root {
  --primary: #4b4b4b;
  --secondary: #2f3136;
  --tertiary: rgb(32, 34, 37);
  --quaternary: #2b352a;
  --quinary: #393d42;
  --senary: #828386;
  --white: #fff;
  --gray: #8a8c90;
  --green: #1a8a4a;
  --green__light: #024b56;
  --yellow: #fbdd03;
  --symbol: #74777a;
  --notification: #f84a4b;
  --discord: #6e86d6;
  --mention-detail: #f9a839;
  --mention-message: #413f3f;
  --link: #5d80d6;
  --red: #8a1a31;
  --greylight: #666666;
}
#tarteaucitronAlertSmall {
   bottom: 40px;
}
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue LT Light", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary);
  overflow-x: hidden;
  font-weight: normal;
  -webkit-tap-highlight-color: transparent; /* transparent with keyword */

}

body p {
  font-family: "Helvetica Neue LT Light", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
}



/* input{display: none;} */

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
}
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-align-center {
  align-items: center;
}

.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.flex-justify-self-end {
  justify-content: flex-end;
}
.width-100 {
  width: 100%;
}
.justify {
  text-align: justify;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}

.size12 {
  font-size: 12px;
}
.size14 {
  font-size: 14px;
}
.size17 {
  font-size: 17px;
}
.size20 {
  font-size: 20px;
}

#root,
.App {
  /*  min-height: 100vh; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
 
}
.icon {
  margin-right: 14px;
}

a:active, a:link, a:visited {
  color : var(--primary);
}
.externalLink:active, .externalLink:link, .externalLink:visited{
  color : var(--white)
}
.externalLink{
  margin-right: 10px;
}

.thin35{
  font-family: 'Helvetica Neue LT Thin';
}

.thin46i{
  font-family: 'Helvetica Neue LT Light Italic';
}

.roman55 {
  font-family: 'Helvetica Neue LT Roman';
  
}

strong {
  font-family: 'Helvetica Neue LT Bold';
}

i{
  font-family: 'Helvetica Neue LT Light Italic';
}

.footer-site__reference{
  margin-left: 0.625rem
}


.app--layout {
  max-width: 1100px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
.app--layout--container {
  width: 100%;
  
}

.footer__navigation-inline a:hover {
  text-decoration: underline;
 }
/* PRE HEADER*/

.app-pre-header {
  height: 40px;
  background-color: #1a3134;
  top: 0;
  position: relative;
  color: var(--white);
}

.app-pre-header__inner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* HEADER*/
.app-header {
  width: 100%;
  height: 232px;
  background-color: var(--green__light);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--white);
}



.app-header h1 {
  font-size: 32px;
  font-weight: 200;
}

.app-header__logo {
  max-width: 265px;
  height: auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 28px;
  margin-left: 10px;
}
.app-header__logo img {
  width: 100%;
  height: 100%;
}

/* FOOTER */

.app-footer {
  width: 100%;
  height: 200px;
  background-color: var(--quaternary);
  position: relative;
  margin-top: 90px;
}

.footer-site_logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-top: 45px;
}
.footer-site__links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white !important;
  width: 100%;
  margin-top: 56px;
  font-weight: 300;
}
.footer-site__copyright {
  color: white !important;
  text-align: center;
  display: flex;
  font-weight: 300;
}

.app-footer a {
  color: white !important;
  text-decoration: none;
  display: inline-block;
  padding: 0rem 0.625rem;
  position: relative;
  font-weight: 300;
}

.app-footer a:not(:last-child):after {
  border-right: solid 0.09375rem white;
  content: "";
  height: 50%;
  margin: auto;
  position: absolute;
  right: 0rem;
  top: 0rem;
  bottom: 0rem;
}

/* Bread*/

.app-bread {
  position: relative;
  height: 25px;
  background-color: #1a3134;
}
.app-bread__inner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 12px;
  color: var(--yellow);
 
  text-transform: uppercase;

}

.app-bread__inner p {
  font-weight: bold;
}
.app-bread__inner a {
  color: var(--yellow);
  
}
.app-bread__inner .active {
  color: var(--white);
}

/* CARD */

.app-content__card {
  margin-top: 39px;
}
.card {
  width: 226px;
  height: 250px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  margin-right: 12px;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 20px;
}
.card--body {
  position: absolute;
  top: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 98%;
 
  
}
.card:last-child {
  margin-right: 0;
}

.card--body button {
  max-width: 140px;
}


.card--title {
  margin: 0 0 9px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  text-align: center;
  color: #4b4b4b;
  height: 56px;
  width: 100%;
  
}
.card--info {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: center;
  color: #4b4b4b;
  font-style: italic;
  margin-top: 21px;
  margin-bottom: 21px;
  width: 100%;
}

.card .photo {
  max-width: 130px;
  position: relative;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.card .photo.photo_1 {
  width: 120px;
  height: auto;
}
.card .photo.photo_2 {
  width: 128px;
  height: auto;
}
.card .photo.photo_3 {
  width: 128px;
  height: auto;
}
.card .photo.photo_4 {
  width: 116px;
  height: auto;
}

/* CONTENT */

.app-content__wrapper {
  margin-left: 75px;
  margin-right: 75px;
}

 .app-content__container {
  margin-top: 55px;
} 
.app-content__pro img {
margin-left: 20px;

}


.app-content__paragraphe {
  font-size: 20px;
  text-align: justify;
  max-width: 662px;
}
/* Button */

button {
  min-width: 122px;
  max-width: 280px;
  height: 27px;
  cursor: pointer;
  padding: 8px 25px 8px 25px;
  border-radius: 13px;
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-animation: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin: 0 auto;
  color: var(--primary) !important;
}

button:hover {
  transform: scale(1.05);
  -webkit-transform: scale();
  -moz-transform: scale();
  -ms-transform: scale();
  -o-transform: scale();
}

button.desktopMargin {
  margin:0 auto;
  margin-top: 30px;
  display: flex;
}

button a {
  color: var(--primary) !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

p.isBad{
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent!important;
  color: var(--red);
}

/* MEDICAL FOLDER */

.app-medical__left {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}

.app-medical__left--menu {
  width: 200px;
  height: auto;
  margin-top: 29px;
  padding: 12px 11px 11px 9px;
  border-radius: 5px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.app-medical__left--menu--li {
  width: 100%;
  height: 26px;
  margin: 0 0 13px;
  border-radius: 5px;
  background-color: #fbdd03;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Helvetica Neue LT Bold';

}
.app-medical__left--menu--li:last-child {
  margin: 0 
}
.app-medical__left--menu--li.isNotActive {
  background-color: transparent;
  opacity: 0.25;
  font-weight: 100;
  font-family: "Helvetica Neue LT Light"
}

.app-medical__left--menu--li a {
  margin-left: 10px;
  margin-right: 10px;
  min-height: 14px;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.14px;
  text-align: left;
  color: #4b4b4b;
  text-decoration: none;
  pointer-events: none;
  touch-action: none;
  white-space: nowrap;
}


.app-medical__left--menu--li.isActive {
  background-color: transparent;
  opacity: 1;
  font-weight: 400;
}
.app-medical__left--menu--li.isActive a {
  font-weight: 400 !important;
}
.app-medical__left--menu--li.isActive a {
  pointer-events: none !important;
  touch-action: none !important;
  font-weight: 400;
}
.app-medical__left--menu--li:not(.isNotActive) a {
  font-weight: 800;
  pointer-events: initial !important;
  touch-action: initial !important;
}
.app-medical__left--menu--li.clicked{
  opacity: 1;
  font-family: 'Helvetica Neue LT Bold';
}
.app-medical__left--menu--li.clicked a {
  font-weight: 800;
  pointer-events: initial !important;
  touch-action: initial !important;
  color: black!important;
}
.app-ssTitle {
  margin-top: 20px;
  margin-left: 35px;
}

.app-medical__right {
  width: 70%;
  text-align: left;
}

.section_title {
  margin-bottom: 38px;
  display: flex;
  align-items: flex-start;
}

.app-examen p {
  font-size: 14px;
  margin-bottom: 20px;
}

.app-header__inner{
  height: 100%!important;
  }
.app-header .app--layout--container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
}

/* PAGE */

.app-medical__title {
  text-align: left;
  margin-top: 29px;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.header-page-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 37px;
}

.header-page__photo img {
  max-width: 160px;
}
.header-page__photo h3 {
  margin-bottom: 20px;
}

.header-page__photo p {
  margin-bottom: 15px;
}

.bloc-header {
  margin-top: 30px;
}

.bloc-paragraph {
  margin-top: 7px;
  margin-left: 11px;
}
/* PAGE INTERROGATOIRE */

.question {
  margin-bottom: 10px;
}

.app-question-bloc__response {
  font-weight: 400;
  margin-bottom: 17px;
}
.app-question-bloc__response.quiz {
  margin-bottom: 0px;
}

.app-question-bloc {
  height: 0px;
  opacity: 0;
  margin-top: 8px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -ms-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 5px;
  padding: 13px 22px 19px 19px;
  background-color: #f4f4f4;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  display: none;
}
.app-question-bloc:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #f4f4f4;
  border-bottom-right-radius: 15px;
}
.app-question-bloc:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.app-question{
  cursor: pointer;
}
.app-question img{
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)  ;
  -webkit-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)  ;
  -moz-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)  ;
  -ms-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)  ;
  -o-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275)  ;
}


.app-question:not(.isopen):hover img{
   transform: rotate(90deg);
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   -o-transform: rotate(90deg);

}


.app-question-bloc.isopen {
  height: auto;
  opacity: 1;
  display: block;
}
/* PAGE SYNTHESE */

.app-bloc-synthese {
  text-align: left;
  margin-bottom: 43px;
}

.app-bloc-synthese:first-child {
  margin-top: 0px;
}

.app-bloc-synthese h3 {
  margin-bottom: 15px;
}

.app-result {
  font-weight: 800;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.app-content__cu{
  margin-top: 30px;
  text-align: justify;
}
.app-content__cu h2{
  margin-bottom: 30px;
}
.app-content__cu p{
  margin-bottom: 20px;
}

.app-content__cu .column-splitter{
  margin-top: 30px;
}
.app-content__cu a, .app-content__cu a:hover {
  color: #296C77;
  font-weight: 800;
}
table tr:first-child td {
 
  background-color: #296C77;
  color: #ffffff;
}

.responsiveImage{
  width: 100%!important;
  height: auto!important;
}

.container_img {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.exac {
  width: 730px;
  max-width: 730px;
  height: 233px;
 
}

table{
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
}

table td, table th {
  border: 0.125rem solid #282828;
  
  font-size: 0.8125rem;
  line-height: 1.4;
  padding: 1rem;
}

.card--ml{
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 10px;
  
 
}

.richtext ul {
  text-align: left!important;
}

.richtext h3{
  text-align: left;
}
/**
 * ADAPTATION MOBILE
 */

 button:disabled {
   opacity: 0.5;
   cursor: none;
   pointer-events: none;
 }
 
 @media only all and (min-width: 800px ) and (max-width: 1010px){
   
  .app-content__wrapper{
    margin-left: 45px;
    margin-right: 45px;
  }
  
  .card--title {
   font-size: 14px;
   }
   .app-medical__left--menu{
     width: 195px;
     margin-right: 15px;
   }
 }
@media only all and (max-width: 800px) {
  .app-medical__left {
    width: calc(100% + 60px);
    flex-direction: row;
    left: 0;
    top: 0;
    position: relative;
    left: -30px;
  }

  .footer-site__reference{
    margin-left: 0rem
  }
  .app-medical__right {
    width: 100%;
  }
  .app-content__wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }

  .app-medical__right h2 {
    font-size: 18px;
  }
  .app-medical__right h3 {
    font-size: 16px;
  }
  .app-medical__left--menu {
    width: 100%;
    height: 30px;
    padding: 10px;
    margin-top: 0;
    overflow: hidden;
  }
  .app-medical__left--menu nav {
    overflow-x: auto;
    display: flex;
    margin: 0 auto;
    height: 30px;
  }
  nav::-webkit-scrollbar {
    display: none;
  }

  
  .app-medical__left--menu ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 30px;
  }
  .app-medical__left--menu ul li {
    height: 30px;
    margin: 0;
    position: relative;
  }

  .app-medical__left--menu ul li.isNotActive:not(:last-child) a:after {
    border-right: solid 0.09375rem #4b4b4b;
    content: "";
    height: 50%;
    margin: auto;
    position: absolute;
    right: 0rem;
    top: 0rem;
    bottom: 0rem;
  }
  .app-content-medical__inner {
    flex-direction: column;
    position: relative;
  }

  .app-content__pro {
    flex-direction: column-reverse;
    margin-top: 10px;
  }

  .app-content__pro img {
    width: 90px;
    margin-bottom: 14px;
   
  }

  .app-content__paragraphe {
    width: 100%;
    max-width: 100%;
    font-size: 14px;
  }

  .app-content__card {
    flex-wrap: wrap;
    justify-content: center;

    
  }
  .card {
    max-width: 150px;
    margin-bottom: 30px;
    
  }
  .size14{
    font-size: 12px!important;
  }
  
  .card--body {
    max-width:  150px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .card--title {
    font-size: 14px;
   
  }
  .card button {
    padding: 0;
    margin: 0;
  }
  .app-footer {
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;
    height: auto;
  }
  .footer-site_logo img {
    width: 90px;
  }
  .footer-site_logo a {
    padding: 0;
  }
  .footer-site__links {
    flex-direction: column;
    margin-top: 25px;
    align-items: flex-start;
  }
  .footer-site__copyright {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
  }
  .footer-site__copyright nav {
    margin-top: 22px;
  }

  .footer-site__links div,
  .footer-site__links a {
    font-size: 10px !important;
  }
  

  .app-header__logo img {
    max-width: 145px;
    margin-left: 10px;
    margin-right: 20px;
  }

  

  .app-header {
    height: 170px;
  }
  .app-header__logo {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .app-header h1 {
    font-size: 30px;
  }

  .app-bread__inner{
    font-size: 10px;
  }

  h3 {
    font-size: 18px;
  }

  .header-page__photo img {
    max-width: 90px;
  }

  .app-pre-header__inner p {
    margin-right: 0px;
    font-size: 12px;
  }

  button.desktopMargin {
    margin: 0 auto;
    display: flex;
    justify-self: center;
    margin-top: 20px;
    
  }

  .app-content__cu p{
    font-size: 12px;
  }
}

@media only all and (max-width: 533px) {

  .card:nth-child(2){
    margin-right: 0;
  }

}
